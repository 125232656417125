import React, { Component } from 'react';
import axios from 'axios';
import '../../App.css';
import minusButton from '../../images/minus.square.png'

//==================================================================================
//==================================================================================

class TicketListPanel extends Component {
    
    //==============================================================================

    constructor( props ) {
        super( props );

        this.state = 
            {
                ticketIds: [], 
                ticketNumbers: [],
                ticketCount: 0,
                selectedRaffleId: this.props.selectedRaffleId 
            };
    }

    //==============================================================================

    componentDidMount() {

        const { selectedRaffleId } = localStorage.getItem( "selectedRaffleId" );
        const url = `${process.env.REACT_APP_CYASOON_GET_ALL_TICKETS_ROUTE}/cyasoonRaffleId=${selectedRaffleId}`;

        if( selectedRaffleId !== undefined && selectedRaffleId !== '' ) {
            axios.get( url )
                .then( response => {
                    let data = response.data;

                    if( data.length > 0 ) {
                        let ticketIds = data.map( ticket => ticket._id );
                        let ticketNumbers = data.map( ticket => ticket.number );
                        var ticketCount = 0;
                        
                        if( ticketIds === undefined || ticketIds.length === 0 ) {
                            ticketCount = 0;
                        }
                        else {
                            ticketCount = ticketIds.length;
                        }

                        this.setState( { ticketIds: ticketIds } );
                        this.setState( { ticketNumbers: ticketNumbers })
                        this.setState( { ticketCount: ticketCount } );
                    }
                } )
                .catch( error => {
                    console.log( "Error: " + error.message );
                } );
        }
    }

    //==============================================================================

    render() {
        const { ticketIds, ticketNumbers } = this.state;

        const handleSelectedTicketIdChange = ( event ) => {
            this.props.onSelectedTicketIdChange( event.target.value );
        };

        const ticketOptions = ticketNumbers.map( ( tickets, index ) => (
            <option key={ ticketIds[ index ] } value={ ticketIds[ index ] }>{ tickets }</option>
        ) );

        //============================================================================
        //Ticket Delete Function
        //============================================================================

        const minusFunction = ( () => { 
            const selectedTicketId = localStorage.getItem( "selectedTicketId" );
            const deleteUrl = `${process.env.REACT_APP_CYASOON_DELETE_TICKET_ROUTE}/cyasoonRaffleTicketId=${selectedTicketId}`;

            if( window.confirm( 'Are you sure you want to delete this ticket?' ) ) {
                axios.get( deleteUrl )
                    .then( response => {
                        let data = response.data;
        
                        if( data.deletedCount > 0 ) {
                            alert( "The ticket was deleted!" );
                        }
                        else {
                            alert( "The ticket was NOT deleted!" );
                        }

                        console.log( data );
                        TicketListPanel.refresh();
                    } )
                    .catch( error => {
                        console.log( "Error: " + error.message );
                    } ); 
            } 
        } );

        //============================================================================

        return (
            <div>
            <select 
                key="ticketList"
                id="ticketList" 
                name="ticketList" 
                className="ticketList" 
                size="15"
                onChange={ handleSelectedTicketIdChange }
                defaultValue={ this.props.selectedTicketId || '' }>
                <option value=""></option>
                {ticketOptions}
            </select>
            <img src={minusButton} id="ticketMinusButton" name="ticketMinusButton" className="ticketMinusButton" onClick={minusFunction} alt="logo" />
            </div>
        );
    }
    
    //==============================================================================

    static refresh() {
        const selectedRaffleId = localStorage.getItem( "selectedRaffleId" );
        const selectedTicketId = localStorage.getItem( "selectedTicketId" );
        
        //============================================================================
        //Ticket Delete Function
        //============================================================================
        
        const deleteUrl = `${process.env.REACT_APP_CYASOON_DELETE_TICKET_ROUTE}/cyasoonRaffleTicketId=${selectedTicketId}`;

        const minusFunction = 
                            "if( window.confirm( 'Are you sure you want to delete this raffle?' ) ) { " + 
                                "axios.get( " + deleteUrl + " )" + 
                                    ".then( response => { " + 
                                        "let data = response.data;" + 
                                        
                                        "if( data.deletedCount > 0 ) { " +
                                            "alert( 'The ticket was deleted!' );" + 
                                        "}" +
                                        "else { " +
                                            "alert( 'The ticket was NOT deleted!' );" +
                                        "}" +                
                                        "console.log( data );" +
                                        "TicketListPanel.refresh();" +
                                        "if( data.length > 0 ) { " +
                                            "alert( 'Ticket Deleted' );" +
                                        "} )" + 
                                    ".catch( error => { " +
                                        "console.log( error );" +
                                    "} );" + 
                            "}";

        //============================================================================

        if( selectedRaffleId !== undefined && selectedRaffleId !== '' ) {

            const url = `${process.env.REACT_APP_CYASOON_GET_ALL_TICKETS_ROUTE}/cyasoonRaffleId=${selectedRaffleId}`;

            axios.get( url )
                .then( response => {
                    let data = response.data;
    
                    if( data.length > 0 ) {
                        let ticketIds = data.map( ticket => ticket._id );
                        let ticketNumbers = data.map( ticket => ticket.number );
                        var ticketCount = 0;
                        var ticketOptions = '';
                        
                        if( ticketIds === undefined || ticketIds.length === 0 ) {
                            ticketCount = 0;
                        }
                        else {
                            ticketCount = ticketIds.length;
                        }
    
                        if( ticketCount > 0 ) {
                            for( var i = 0; i < ticketCount; ++i ) {
                                ticketOptions = ticketOptions + "<option key=" + ticketIds[ i ] + " value=" + ticketIds[ i ] + ( ( ticketIds[ i ] === selectedTicketId ) ? " selected" : "" ) + ">" + ticketNumbers[ i ] + "</option>";
                            }
                        }
    
                        document.getElementById( 'ticketList' ).innerHTML =
                            `<div><select key="ticketList" id="ticketList" name="ticketList" class="ticketList" size="15"><option value=""></option>` + ticketOptions + 
                            `</select><img src=${minusButton} id="ticketMinusButton" name="ticketMinusButton" class="ticketMinusButton" onclick="` + minusFunction + `" alt="logo" /></div>`;
                    }
                    else {
                        document.getElementById( 'ticketList' ).innerHTML =
                            `<div><select key="ticketList" id="ticketList" name="ticketList" class="ticketList" size="15"><option value=""></option></select>` + 
                            `<img src=${minusButton} id="ticketMinusButton" name="ticketMinusButton" class="ticketMinusButton" onclick="` + minusFunction + `" alt="logo" /></div>`;
                    }
                } )
                .catch( error => {
                    console.log( "Error: " + error.message );
                } );
        }
        else {
            document.getElementById( 'ticketList' ).innerHTML =
                `<div><select key="ticketList" id="ticketList" name="ticketList" class="ticketList" size="15"><option value=""></option></select>` + 
                `<img src=${minusButton} id="ticketMinusButton" name="ticketMinusButton" class="ticketMinusButton" onclick="` + minusFunction + `" alt="logo" /></div>`;
        }
    }

    //==============================================================================

    static getTicketIdValue() {
        return document.getElementById( 'ticketList' ).value;
    }

    //==============================================================================

    static getTicketListValue() {
        var eventList = document.getElementById( 'ticketList' );

        if( eventList.selectedIndex === -1 )
            return null;

        return eventList.options[ eventList.selectedIndex ].text;
    }

    //==============================================================================

};

//==================================================================================
//==================================================================================

export default TicketListPanel;