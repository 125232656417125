import React, { Component } from 'react';
import axios from 'axios';
import '../../App.css';
import minusButton from '../../images/minus.square.png'
import RaffleListPanel from './raffleListPanel.js';
import TicketListPanel from './ticketListPanel.js';

//==================================================================================
//==================================================================================

class EventListPanel extends Component {
    
    //==============================================================================

    constructor( props ) {
        super( props );
        
        this.state = 
            { 
                eventIds: [], 
                eventNames: [],
                eventCodes: [],
                eventCount: 0
            };
    };

    //==============================================================================

    componentDidMount() {
        axios.get( process.env.REACT_APP_CYASOON_GET_ALL_EVENTS_ROUTE )
            .then( response => {
                let data = response.data;

                if( data.length > 0 ) {
                    let eventIds = data.map( event => event._id );
                    let eventNames = data.map( event => event.name );
                    let eventCodes = data.map( event => event.code );
                    var eventCount = 0;
                    
                    if( eventIds === undefined || eventIds.length === 0 ) {
                        eventCount = 0;
                    }
                    else {
                        eventCount = eventIds.length;
                    }

                    this.setState( { eventIds: eventIds } );
                    this.setState( { eventNames: eventNames } );
                    this.setState( { eventCodes: eventCodes } );
                    this.setState( { eventCount: eventCount } );
                }
            } )
            .catch( error => {
                console.log( "Error: " + error.message );
            } );
    };

    //==============================================================================

    render() {
        const { eventIds, eventNames, eventCodes } = this.state;

        const handleSelectedEventIdChange = ( event ) => {
            this.props.onSelectedEventIdChange( event.target.value );
        };

        const eventOptions = eventNames.map( ( names, index ) => (
            <option key={ eventIds[ index ] } value={ eventIds[ index ] }>{ names } [Code:{ eventCodes[ index ] }]</option>
        ) );

        //============================================================================
        //Event Delete Function
        //============================================================================

        const minusFunction = ( () => { 
            const selectedEventId = localStorage.getItem( "selectedEventId" );
            const deleteUrl = `${process.env.REACT_APP_CYASOON_DELETE_EVENT_ROUTE}/cyasoonEventId=${selectedEventId}`;

            if( window.confirm( 'Are you sure you want to delete this event?' ) ) {
                axios.get( deleteUrl )
                    .then( response => {
                        let data = response.data;
        
                        if( data.deletedCount > 0 ) {
                            alert( "The event was deleted!" );
                        }
                        else {
                            alert( "The event was NOT deleted!" );
                        }

                        console.log( data );
                        EventListPanel.refresh();
                        RaffleListPanel.refresh();
                        TicketListPanel.refresh();
                    } )
                    .catch( error => {
                        console.log( "Error: " + error.message );
                    } ); 
            }
        } );

        //============================================================================

        return (
            <div>
            <select
                key="eventList"
                id="eventList"
                name="eventList"
                className="eventList"
                size="15"
                onChange={ handleSelectedEventIdChange }
                defaultValue={ this.props.selectedEventId || '' }>
                <option value=""></option> 
                { eventOptions }
            </select>
            <img src={minusButton} id="eventMinusButton" name="eventMinusButton" className="eventMinusButton" onClick={minusFunction} alt="logo" />
            </div>
        );
    };
    
    //==============================================================================

    static refresh() {
        const selectedEventId = localStorage.getItem( "selectedEventId" );
        
        //============================================================================
        //Event Delete Function
        //============================================================================
        
        const deleteUrl = `${process.env.REACT_APP_CYASOON_DELETE_EVENT_ROUTE}/cyasoonEventId=${selectedEventId}`;

        const minusFunction = 
                            "if( window.confirm( 'Are you sure you want to delete this event?' ) ) { " + 
                                "axios.get( " + deleteUrl + " )" + 
                                    ".then( response => { " + 
                                        "let data = response.data;" + 
                                        
                                        "if( data.deletedCount > 0 ) { " +
                                            "alert( 'The event was deleted!' );" + 
                                        "}" +
                                        "else { " +
                                            "alert( 'The event was NOT deleted!' );" +
                                        "}" +                
                                        "console.log( data );" +
                                        "EventListPanel.refresh();" +
                                        "RaffleListPanel.refresh();" +
                                        "TicketListPanel.refresh();" +
                                        "if( data.length > 0 ) { " +
                                            "alert( 'Event Deleted' );" +
                                        "} )" + 
                                    ".catch( error => { " +
                                        "console.log( error );" +
                                    "} );" + 
                            "}";

        //============================================================================

        var finalSelectHTML = null;

        axios.get( process.env.REACT_APP_CYASOON_GET_ALL_EVENTS_ROUTE )
            .then( response => {
                let data = response.data;

                if( data.length > 0 ) {
                    let eventIds = data.map( event => event._id );
                    let eventNames = data.map( event => event.name );
                    let eventCodes = data.map( event => event.code );
                    var eventCount = 0;
                    
                    var eventOptions = '';
                    
                    if( eventIds === undefined || eventIds.length === 0 ) {
                        eventCount = 0;
                    }
                    else {
                        eventCount = eventIds.length;
                    }

                    if( eventCount > 0 ) {
                        for( var i = 0; i < eventCount; ++i ) {
                            eventOptions = eventOptions + "<option key=" + eventIds[ i ] + " value=" + eventIds[ i ] + ">" + eventNames[ i ] + " [Code: " + eventCodes[ i ] + "]</option>";
                        }
                    }

                    finalSelectHTML = 
                        `<select key="eventList" id="eventList" name="eventList" className="eventList" size="15"><option value=""></option>` + eventOptions + 
                        `</select><img src=${minusButton} id="eventMinusButton" name="eventMinusButton" class="eventMinusButton" onclick="` + minusFunction + `" alt="logo" /></div>`;
                    
                    document.getElementById( 'eventList' ).innerHTML = finalSelectHTML;    
                }
                else {
                    finalSelectHTML = 
                        `<select key="eventList" id="eventList" name="eventList" className="eventList" size="15"><option value=""></option></select>` +
                        `<img src=${minusButton} id="eventMinusButton" name="eventMinusButton" class="eventMinusButton" onclick="` + minusFunction + `" alt="logo" /></div>`;
                    
                    document.getElementById( 'eventList' ).innerHTML = finalSelectHTML;    
                }
            } )
            .catch( error => {
                console.log( "Error: " + error.message );
            } );
    };

    //==============================================================================

    static getEventIdValue() {
        return document.getElementById( 'eventList' ).value;
    };

    //==============================================================================

    static getEventListValue() {
        var eventList = document.getElementById( 'eventList' );

        if( eventList.selectedIndex === -1 )
            return null;

        return eventList.options[ eventList.selectedIndex ].text;
    };

    //==============================================================================

};

//==================================================================================
//==================================================================================

export default EventListPanel;