import React, { Component } from 'react';
import '../../App.css';

//==================================================================================
//==================================================================================

class RafflePanel extends Component {
    
    //==============================================================================

    constructor( props ) {
        super( props );

        this.state = { raffleName: '', raffleCode: '' };
    }

    //==============================================================================

    componentDidMount() {}

    //==============================================================================

    render() {
        return (
            <div>
                <input type="text" id="raffleNameField" name="raffleNameField" className="raffleNameField" placeholder="Enter Raffle Name" />
                <input type="text" id="raffleCodeField" name="raffleCodeField" className="raffleCodeField" placeholder="Assign Code" />
            </div>
        );
    }
    
    //==============================================================================

    static getRaffleName() {
        var raffleNameField = document.getElementById( 'raffleNameField' );
        return raffleNameField.value;
    }

    //==============================================================================

    static getRaffleCode() {
        var raffleCodeField = document.getElementById( 'raffleCodeField' );
        return raffleCodeField.value;
    }

    //==============================================================================

    static clearRaffleNameField() {
        var raffleNameField = document.getElementById( 'raffleNameField' );
        raffleNameField.value = '';
    }

    //==============================================================================

    static clearRaffleCodeField() {
        var raffleCodeField = document.getElementById( 'raffleCodeField' );
        raffleCodeField.value = '';
    }

    //==============================================================================

};

//==================================================================================
//==================================================================================

export default RafflePanel;