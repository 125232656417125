import React, { Component } from 'react';
import '../../App.css';

//==================================================================================
//==================================================================================

class TicketPanel extends Component {
    
    //==============================================================================

    constructor( props ) {
        super( props );

        this.state = { ticketQuantity: 0 };
    }

    //==============================================================================

    componentDidMount() {}

    //==============================================================================

    render() {
        return (
            <input type="text" id="ticketQuantityField" name="ticketQuantityField" className="ticketQuantityField" placeholder="Enter Ticket Quantity" />
        );
    }
    
    //==============================================================================

    static getTicketQuantity() {
        var ticketQuantityField = document.getElementById( 'ticketQuantityField' );
        return ticketQuantityField.value;
    }

    //==============================================================================

    static clearTicketQuantityField() {
        var ticketQuantityField = document.getElementById( 'ticketQuantityField' );
        ticketQuantityField.value = '';
    }

    //==============================================================================

};

//==================================================================================
//==================================================================================

export default TicketPanel;