import axios from 'axios';
import React, {useState, useEffect} from 'react';

import EventPanel from './components/panels/eventPanel.js';
import RafflePanel from './components/panels/rafflePanel.js';
import TicketPanel from './components/panels/ticketPanel.js';

import EventListPanel from './components/panels/eventListPanel.js';
import RaffleListPanel from './components/panels/raffleListPanel.js';
import TicketListPanel from './components/panels/ticketListPanel.js';

import EntrantPanel from './components/panels/entrantPanel.js';

import PasswordPage from './PasswordPage';

import logo from './images/CyasoonProLogo.png';

import './App.css';
import './css/fonts.css';
import './css/main.css';

//==============================================================================

function handleCreateEvent() {

  const eventName = EventPanel.getEventName();
  const eventCode = EventPanel.getEventCode();

  EventPanel.clearEventNameField();
  EventPanel.clearEventCodeField();
  
  const url = `${process.env.REACT_APP_CYASOON_CREATE_EVENT_ROUTE}/name=${eventName}/code=${eventCode}`;

  axios.get( url )
    .then( response => {
        let data = response.data;

        if( data.length > 0 ) {
            let modifiedDocuments = data.map( result => result.modifiedCount );
            console.log( "modifiedDocuments = " + modifiedDocuments );
        }

        EventListPanel.refresh();
    } )
    .catch( error => {
        console.log( "Error: " + error.message );
    } );
};

//==============================================================================

function handleCreateRaffle() {
  
  const selectedEventId = localStorage.getItem( "selectedEventId" );
  
  const raffleName = RafflePanel.getRaffleName();
  const raffleCode = RafflePanel.getRaffleCode();
  
  RafflePanel.clearRaffleNameField();
  RafflePanel.clearRaffleCodeField();
  
  const url = `${process.env.REACT_APP_CYASOON_CREATE_RAFFLE_ROUTE}/cyasoonEventId=${selectedEventId}/name=${raffleName}/code=${raffleCode}`;

  axios.get( url )
    .then( response => {
        let data = response.data;

        if( data.length > 0 ) {
            let modifiedDocuments = data.map( result => result.modifiedCount );
            console.log( "modifiedDocuments = " + modifiedDocuments );
        }

        RaffleListPanel.refresh();
    } )
    .catch( error => {
        console.log( "Error: " + error.message );
    } );
}

//==============================================================================

function handleCreateTickets() {
  
  const selectedRaffleId = localStorage.getItem( "selectedRaffleId" );
  const ticketQuantity = TicketPanel.getTicketQuantity();
  TicketPanel.clearTicketQuantityField();

  const url = `${process.env.REACT_APP_CYASOON_CREATE_TICKETS_ROUTE}/cyasoonRaffleId=${selectedRaffleId}/quantity=${ticketQuantity}`;

  axios.get( url )
    .then( response => {
        let data = response.data;

        if( data.length > 0 ) {
            let modifiedDocuments = data.map( result => result.modifiedCount );
            console.log( "modifiedDocuments = " + modifiedDocuments );
        }

        TicketListPanel.refresh();
    } )
    .catch( error => {
        console.log( "Error: " + error.message );
    } );
};

//==============================================================================

function handleDrawTicket() {

  const selectedEventId = localStorage.getItem( "selectedEventId" );
  const selectedRaffleId = localStorage.getItem( "selectedRaffleId" );
  
  const drawTicketFromEventUrl = `${process.env.REACT_APP_CYASOON_DRAW_ASSIGNED_TICKET_BY_EVENT_ROUTE}/cyasoonEventId=${selectedEventId}`;
  const drawTicketFromRaffleUrl = `${process.env.REACT_APP_CYASOON_DRAW_ASSIGNED_TICKET_BY_RAFFLE_ROUTE}/cyasoonRaffleId=${selectedRaffleId}`;

  var drawTicketResultPanel = null;

  if( selectedRaffleId !== undefined && selectedRaffleId !== '' ) {
    axios.get( drawTicketFromRaffleUrl )
      .then( response => {
          let data = response.data;

          if( data !== undefined && data.entrantTicket !== undefined ) {
            let entrantTicket = data.entrantTicket;
            
            let winningTicketNumber = entrantTicket.number;
            let winningEmailAddress = entrantTicket.emailAddress;
            
            localStorage.setItem( "winningTicketNumber", winningTicketNumber );
            localStorage.setItem( "winningEmailAddress", winningEmailAddress );

            drawTicketResultPanel = document.getElementById( 'drawTicketResultPanel' );
            drawTicketResultPanel.innerHTML = '<span class="bigNumber">' + winningTicketNumber + '</span>';
          }
          else {
            drawTicketResultPanel = document.getElementById( 'drawTicketResultPanel' );
            drawTicketResultPanel.innerHTML = "No tickets available";
          }
      } )
      .catch( error => {
          console.log( "Error: " + error.message );
      } );
  }
  else if( selectedEventId !== undefined && selectedEventId !== '' ) {
    axios.get( drawTicketFromEventUrl )
      .then( response => {
          let data = response.data;

          if( data !== undefined && data.entrantTicket !== undefined ) {
            let entrantTicket = data.entrantTicket;
            
            let winningTicketNumber = entrantTicket.number;
            let winningEmailAddress = entrantTicket.emailAddress;
            
            localStorage.setItem( "winningTicketNumber", winningTicketNumber );
            localStorage.setItem( "winningEmailAddress", winningEmailAddress );

            drawTicketResultPanel = document.getElementById( 'drawTicketResultPanel' );
            drawTicketResultPanel.innerHTML = '<span class="bigNumber">' + winningTicketNumber + '</span>';
          }
          else {
            drawTicketResultPanel = document.getElementById( 'drawTicketResultPanel' );
            drawTicketResultPanel.innerHTML = "No tickets available";
          }
      } )
      .catch( error => {
          console.log( "Error: " + error.message );
      } );
  }
  else {
    drawTicketResultPanel = document.getElementById( 'drawTicketResultPanel' );
    drawTicketResultPanel.innerHTML = "No tickets available";
  }
};

//==============================================================================

function handleAssignWinner() {
  alert( 'Assigning Winner ...' );
};

//==============================================================================

function App() {
  const [ isAuthenticated, setIsAuthenticated ] = useState( false );

  useEffect( () => {
    const storedAuth = localStorage.getItem( 'isAuthenticated' );
    
    if( storedAuth ) {
      setIsAuthenticated( true );
    }
  }, [] );

  var [ selectedEventId ] = useState( 'CYASOON_PRO' );
  var [ selectedRaffleId ] = useState( 'CYASOON_PRO' );
  var [ selectedTicketId ] = useState( 'CYASOON_PRO' );

  localStorage.setItem( "selectedEventId", "" );
  localStorage.setItem( "selectedRaffleId", "" );
  localStorage.setItem( "selectedTicketId", "" );

  //============================================================================
  //Inner functions
  //============================================================================
  
  const handleSelectedEventIdChange = ( newSelectedEventId ) => {
    selectedEventId = newSelectedEventId;
    
    localStorage.setItem( "selectedEventId", selectedEventId );
    localStorage.setItem( "selectedRaffleId", "" );
    localStorage.setItem( "selectedTicketId", "" );
    
    RaffleListPanel.refresh();
    TicketListPanel.refresh();

    EntrantPanel.refresh();
  };

  //============================================================================

  const handleSelectedRaffleIdChange = ( newSelectedRaffleId ) => {
    selectedRaffleId = newSelectedRaffleId;
    
    localStorage.setItem( "selectedRaffleId", selectedRaffleId );
    localStorage.setItem( "selectedTicketId", "" );
    
    TicketListPanel.refresh();

    EntrantPanel.refresh();
  };

  //============================================================================

  const handleSelectedTicketIdChange = ( newSelectedTicketId ) => {
    selectedTicketId = newSelectedTicketId;

    localStorage.setItem( "selectedTicketId", selectedTicketId );

    TicketListPanel.refresh();
  };
  
  //============================================================================

  return (
    <>
      { isAuthenticated ? (
        <div className="app">
        <header className="appHeader">
          <img src={logo} className="appLogo" alt="logo" />
        </header>

        <div className="proUsers">
          Shaka Club
        </div>

        <div className="mainPanel">
          <div className="setupPanel">

            <div className="createEventPanel">
              <div style={{height: 20, marginTop: 10, marginBottom: 10}}>
                New Event
              </div>
              
              <div className="eventPanel">
                <EventPanel />
              </div>
            </div>

            <div className="createRafflePanel">
              <div style={{height: 20, marginTop: 10, marginBottom: 10}}>
                New Raffle
              </div>

              <div className="rafflePanel">
                <RafflePanel />
              </div>
            </div>

            <div className="createTicketPanel">
              <div style={{height: 20, marginTop: 10, marginBottom: 10}}>
                Ticket Quantity
              </div>

              <div className="ticketPanel">
                <TicketPanel />
              </div>
            </div>
          </div>

          <div className="buttonPanel">
            <div className="createEventButtonPanel">
              <button id="createEventButton" name="createEventButton" onClick={handleCreateEvent} className="primary-btn">Create Event</button>
            </div>
            
            <div className="createRaffleButtonPanel">
              <button id="createRaffleButton" name="createRaffleButton" onClick={handleCreateRaffle} className="primary-btn">Create Raffle</button>
            </div>
            
            <div className="createTicketsButtonPanel">
              <button id="createTicketsButton" name="createTicketsButton" onClick={handleCreateTickets} className="primary-btn">Create Tickets</button>
            </div>
          </div>

          <div className="listPanel">
            <div className="eventDisplayPanel">
              <div style={{height: 20, marginTop: 10, marginBottom: 10}}>
                Events
              </div>
              
              <div className="eventListPanel">
                <EventListPanel selectedEventId={selectedEventId} onSelectedEventIdChange={handleSelectedEventIdChange} />
              </div>
            </div>

            <div className="raffleDisplayPanel">
              <div style={{height: 20, marginTop: 10, marginBottom: 10}}>
                Raffles
              </div>
              
              <div className="raffleListPanel">
                <RaffleListPanel selectedRaffleId={selectedRaffleId} onSelectedRaffleIdChange={handleSelectedRaffleIdChange} />
              </div>
            </div>

            <div className="ticketDisplayPanel">
              <div style={{height: 20, marginTop: 10, marginBottom: 10}}>
                Tickets
              </div>
              
              <div className="ticketListPanel">
                <TicketListPanel selectedTicketId={selectedTicketId} onSelectedTicketIdChange={handleSelectedTicketIdChange} />
              </div>
            </div>
          </div>

        </div>

        <div className="contestPanel">
          <div className="entrantPanel">
            <div style={{height: 20, marginTop: 20, marginBottom: 10}}>
              Entrants
            </div>

            <div className="eventPanel">
              <EntrantPanel selectedEventId={selectedEventId} />
            </div>
          </div>

          <div className="drawPanel">
            <div style={{height: 20, marginTop: 20, marginBottom: 10}}>
              Draws
            </div>

            <div className="drawTicketButtonPanel">
              <button id="drawTicketButton" name="drawTicketButton" onClick={handleDrawTicket} className="primary-btn">Draw Ticket</button>
            </div>

            <div id="drawTicketResultPanel" name="drawTicketResultPanel" className="drawTicketResultPanel">Select an Event or Raffle</div>

            <div className="assignWinnerButtonPanel">
              <button id="assignWinnerButton" name="assignWinnerButton" onClick={handleAssignWinner} className="primary-btn">Assign Winner</button>
            </div>
          </div>

          <div className="winnerPanel">
            <div style={{height: 20, marginTop: 20, marginBottom: 10}}>
              Winners
            </div>
          </div>
        </div>

      </div>  
      ) : (
        <PasswordPage setIsAuthenticated={setIsAuthenticated} />
      ) }
    </>
  );
};

//==============================================================================

export default App;