import React, { Component } from 'react';
import '../../App.css';

//==================================================================================
//==================================================================================

class EventPanel extends Component {
    
    //==============================================================================

    constructor( props ) {
        super( props );

        this.state = { eventName: '', eventCode: '' };
    }

    //==============================================================================

    componentDidMount() {}

    //==============================================================================

    render() {
        return (
            <div>
                <input type="text" id="eventNameField" name="eventNameField" className="eventNameField" placeholder="Enter Event Name" />
                <input type="text" id="eventCodeField" name="eventCodeField" className="eventCodeField" placeholder="Assign Code" />
            </div>
        );
    };
    
    //==============================================================================

    static getEventName() {
        var eventNameField = document.getElementById( 'eventNameField' );
        return eventNameField.value;
    };

    //==============================================================================

    static getEventCode() {
        var eventCodeField = document.getElementById( 'eventCodeField' );
        return eventCodeField.value;
    };

    //==============================================================================

    static clearEventNameField() {
        var eventNameField = document.getElementById( 'eventNameField' );
        eventNameField.value = '';
    };

    //==============================================================================

    static clearEventCodeField() {
        var eventCodeField = document.getElementById( 'eventCodeField' );
        eventCodeField.value = '';
    };

    //==============================================================================

};

//==================================================================================
//==================================================================================

export default EventPanel;