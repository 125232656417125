import React, { Component } from 'react';
import axios from 'axios';
import '../../App.css';
import minusButton from '../../images/minus.square.png'
import TicketListPanel from './ticketListPanel.js';

//==================================================================================
//==================================================================================

class RaffleListPanel extends Component {
    
    //==============================================================================

    constructor( props ) {
        super( props );

        this.state = 
            {
                raffleIds: [], 
                raffleNames: [],
                raffleCodes: [],
                raffleCount: 0,
                selectedEventId: this.props.selectedEventId 
            };
    }

    //==============================================================================

    componentDidMount() {
        
        const { selectedEventId } = localStorage.getItem( "selectedEventId" );
        const url = `${process.env.REACT_APP_CYASOON_GET_ALL_RAFFLES_ROUTE}/cyasoonEventId=${selectedEventId}`;
        
        if( selectedEventId !== undefined && selectedEventId !== '' ) {
            axios.get( url )
                .then( response => {
                    let data = response.data;

                    if( data.length > 0 ) {
                        let raffleIds = data.map( raffle => raffle._id );
                        let raffleNames = data.map( raffle => raffle.name );
                        let raffleCodes = data.map( raffle => raffle.code );
                        var raffleCount = 0;
                        
                        if( raffleIds === undefined || raffleIds.length === 0 ) {
                            raffleCount = 0;
                        }
                        else {
                            raffleCount = raffleIds.length;
                        }

                        this.setState( { raffleIds: raffleIds } );
                        this.setState( { raffleNames: raffleNames })
                        this.setState( { raffleCodes: raffleCodes } );
                        this.setState( { raffleCount: raffleCount } );
                    }
                } )
                .catch( error => {
                    console.log( "Error: " + error.message );
                } );
        }
    }

    //==============================================================================

    render() {
        const { raffleIds, raffleNames, raffleCodes } = this.state;

        const handleSelectedRaffleIdChange = ( event ) => {
            this.props.onSelectedRaffleIdChange( event.target.value );
        };

        const raffleOptions = raffleNames.map( ( raffles, index ) => (
            <option key={raffleIds[ index ]} value={raffleIds[ index ]}>{raffles} [Code:{ raffleCodes[ index ] }]</option>
        ) );
    
        //============================================================================
        //Raffle Delete Function
        //============================================================================

        const minusFunction = ( () => { 
            const selectedRaffleId = localStorage.getItem( "selectedRaffleId" );
            const deleteUrl = `${process.env.REACT_APP_CYASOON_DELETE_RAFFLE_ROUTE}/cyasoonEventRaffleId=${selectedRaffleId}`;

            if( window.confirm( 'Are you sure you want to delete this raffle?' ) ) {
                axios.get( deleteUrl )
                    .then( response => {
                        let data = response.data;
        
                        if( data.deletedCount > 0 ) {
                            alert( "The raffle was deleted!" );
                        }
                        else {
                            alert( "The raffle was NOT deleted!" );
                        }

                        console.log( data );
                        RaffleListPanel.refresh();
                        TicketListPanel.refresh();
                    } )
                    .catch( error => {
                        console.log( "Error: " + error.message );
                    } ); 
            }
        } );

        //============================================================================

        return (
            <div>
            <select 
                key="raffleList" 
                id="raffleList" 
                name="raffleList" 
                className="raffleList" 
                size="15"
                onChange={ handleSelectedRaffleIdChange }
                defaultValue={ this.props.selectedRaffleId || '' }>
                <option value=""></option>
                {raffleOptions}
            </select>
            <img src={minusButton} id="raffleMinusButton" name="raffleMinusButton" className="raffleMinusButton" onClick={minusFunction} alt="logo" />
            </div>
        );
    }
    
     //==============================================================================

     static refresh() {
        const selectedEventId = localStorage.getItem( "selectedEventId" );
        const selectedRaffleId = localStorage.getItem( "selectedRaffleId" );
        
        //============================================================================
        //Raffle Delete Function
        //============================================================================
        
        const deleteUrl = `${process.env.REACT_APP_CYASOON_DELETE_RAFFLE_ROUTE}/cyasoonEventRaffleId=${selectedRaffleId}`;

        const minusFunction = 
                            "if( window.confirm( 'Are you sure you want to delete this raffle?' ) ) { " + 
                                "axios.get( " + deleteUrl + " )" + 
                                    ".then( response => { " + 
                                        "let data = response.data;" + 
                                        
                                        "if( data.deletedCount > 0 ) { " +
                                            "alert( 'The raffle was deleted!' );" + 
                                        "}" +
                                        "else { " +
                                            "alert( 'The raffle was NOT deleted!' );" +
                                        "}" +                
                                        "console.log( data );" +
                                        "RaffleListPanel.refresh();" +
                                        "TicketListPanel.refresh();" +
                                        "if( data.length > 0 ) { " +
                                            "alert( 'Raffle Deleted' );" +
                                        "} )" + 
                                    ".catch( error => { " +
                                        "console.log( error );" +
                                    "} );" + 
                            "}";

        //============================================================================

        if( selectedEventId !== undefined && selectedEventId !== '' ) {

            const url = `${process.env.REACT_APP_CYASOON_GET_ALL_RAFFLES_ROUTE}/cyasoonEventId=${selectedEventId}`;

            axios.get( url )
                .then( response => {
                    let data = response.data;
    
                    if( data.length > 0 ) {
                        let raffleIds = data.map( raffle => raffle._id );
                        let raffleNames = data.map( raffle => raffle.name );
                        let raffleCodes = data.map( raffle => raffle.code );
                        var raffleCount = 0;
                        var raffleOptions = '';
                        
                        if( raffleIds === undefined || raffleIds.length === 0 ) {
                            raffleCount = 0;
                        }
                        else {
                            raffleCount = raffleIds.length;
                        }
    
                        if( raffleCount > 0 ) {
                            for( var i = 0; i < raffleCount; ++i ) {
                                raffleOptions = raffleOptions + "<option key=" + raffleIds[ i ] + " value=" + raffleIds[ i ] + ">" + raffleNames[ i ] + " [Code: " + raffleCodes[ i ] + "]</option>";
                            }
                        }
    
                        document.getElementById( 'raffleList' ).innerHTML =
                            `<div><select key="raffleList" id="raffleList" name="raffleList" class="raffleList" size="15"><option value=""></option>` + raffleOptions + 
                            `</select><img src=${minusButton} id="raffleMinusButton" name="raffleMinusButton" class="raffleMinusButton" onclick="` + minusFunction + `" alt="logo" /></div>`;
                    }
                    else {
                        document.getElementById( 'raffleList' ).innerHTML =
                            `<div><select key="raffleList" id="raffleList" name="raffleList" class="raffleList" size="15"><option value=""></option></select>` + 
                            `<img src=${minusButton} id="raffleMinusButton" name="raffleMinusButton" class="raffleMinusButton" onclick="` + minusFunction + `" alt="logo" /></div>`;
                    }
                } )
                .catch( error => {
                    console.log( "Error: " + error.message );
                } );
        }
        else {
            document.getElementById( 'raffleList' ).innerHTML =
                `<div><select key="raffleList" id="raffleList" name="raffleList" class="raffleList" size="15"><option value=""></option></select>` + 
                `<img src=${minusButton} id="raffleMinusButton" name="raffleMinusButton" class="raffleMinusButton" onclick="` + minusFunction + `" alt="logo" /></div>`;
        }
    }

    //==============================================================================

    static getRaffleIdValue() {
        return document.getElementById( 'raffleList' ).value;
    }

    //==============================================================================

    static getRaffleListValue() {
        var raffleList = document.getElementById( 'raffleList' );

        if( raffleList.selectedIndex === -1 )
            return null;

        return raffleList.options[ raffleList.selectedIndex ].text;
    }

    //==============================================================================

};

//==================================================================================
//==================================================================================

export default RaffleListPanel;