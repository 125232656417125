import React, { Component } from 'react';
import axios from 'axios';
import '../../App.css';

//==================================================================================
//==================================================================================

class EntrantPanel extends Component {
    
    //==============================================================================

    constructor( props ) {
        super( props );

        this.state = { 
            entrantEmailAddresses: [], 
            entrantTicketNumbers: [],
            entrantCount: 0,
            selectedEventId: this.props.selectedEventId 
        };
    }

    //==============================================================================

    componentDidMount() {
        
        const { selectedEventId } = localStorage.getItem( "selectedEventId" );
        const { selectedRaffleId } = localStorage.getItem( "selectedRaffleId" );
        const url = `${process.env.REACT_APP_CYASOON_GET_ALL_ASSIGNED_TICKETS_TO_ALL_USERS_BY_EVENT_ROUTE}/cyasoonEventId=${selectedEventId}`;

        if( selectedRaffleId !== undefined && selectedRaffleId !== '' ) {
            axios.get( url )
                .then( response => {
                    let data = response.data;

                    if( data.length > 0 ) {
                        let entrantEmailAddresses = data.map( entrant => entrant.emailAddress );
                        let entrantTicketNumbers = data.map( entrant => entrant.number );
                        var entrantCount = 0;
                        
                        if( entrantEmailAddresses === undefined || entrantEmailAddresses.length === 0 ) {
                            entrantCount = 0;
                        }
                        else {
                            entrantCount = entrantEmailAddresses.length;
                        }

                        this.setState( { entrantEmailAddresses: entrantEmailAddresses } );
                        this.setState( { entrantTicketNumbers: entrantTicketNumbers } );
                        this.setState( { entrantCount: entrantCount } );
                    }
                } )
                .catch( error => {
                    console.log( "Error: " + error.message );
                } );
        }
        else if( selectedEventId !== undefined && selectedEventId !== '' ) {
            axios.get( url )
                .then( response => {
                    let data = response.data;

                    if( data.length > 0 ) {
                        let entrantEmailAddresses = data.map( entrant => entrant.emailAddress );
                        let entrantTicketNumbers = data.map( entrant => entrant.number );
                        var entrantCount = 0;
                        
                        if( entrantEmailAddresses === undefined || entrantEmailAddresses.length === 0 ) {
                            entrantCount = 0;
                        }
                        else {
                            entrantCount = entrantEmailAddresses.length;
                        }

                        this.setState( { entrantEmailAddresses: entrantEmailAddresses } );
                        this.setState( { entrantTicketNumbers: entrantTicketNumbers } );
                        this.setState( { entrantCount: entrantCount } );
                    }
                } )
                .catch( error => {
                    console.log( "Error: " + error.message );
                } );
        }
    }

    //==============================================================================

    render() {

        var entrantCount = this.state[ "entrantCount" ];

        return (
            <div className="entrantTableContainer">
                <table id="entrantTable" name="entrantTable" cellSpacing="0" cellPadding="0" className="entrantTable">
                    <thead>
                        <tr>
                            <th className="emailAddressHeading">Email Address</th>
                            <th className="ticketNumberHeading">Ticket</th>
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            ( entrantCount === 0 ) ? (
                                <tr>
                                    <td className="emailAddressColumn" colSpan="2" style={ { textAlign: "center" } }>No entrants available</td>
                                </tr>
                            ) : (
                                <tr>
                                    <td className="emailAddressColumn">We have entrants</td> 
                                    <td className="ticketNumberColumn">And tickets</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        );
    };
    
    //==============================================================================

    static refresh() {
        
        const selectedEventId = localStorage.getItem( "selectedEventId" );
        const selectedRaffleId = localStorage.getItem( "selectedRaffleId" );
        
        if( selectedRaffleId !== undefined && selectedRaffleId !== '' ) {
            const url = `${process.env.REACT_APP_CYASOON_GET_ALL_ASSIGNED_TICKETS_TO_ALL_USERS_BY_RAFFLE_ROUTE}/cyasoonRaffleId=${selectedRaffleId}`;

            axios.get( url )
                .then( response => {
                    let data = response.data;

                    if( data.entrants.length > 0 ) {
                        let entrants = data.entrants;
                        var entrantCount = 0;
                        
                        if( entrants === undefined || entrants.length === 0 ) {
                            entrantCount = 0;
                        }
                        else {
                            entrantCount = entrants.length;
                        }

                        var updatedTableHTML = "";

                        if( entrantCount === 0 ) {
                            updatedTableHTML = 
                            `<thead>
                                <tr>
                                    <th class="emailAddressHeading">Email Address</th>
                                    <th class="ticketNumberHeading">Ticket</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="emailAddressColumn" colspan="2" style="text-align: center;">No entrants available</td>
                                </tr>
                            </tbody>`;
                        }
                        else {
                            updatedTableHTML = 
                            `<thead>
                                <tr>
                                    <th class="emailAddressHeading">Email Address</th>
                                    <th class="ticketNumberHeading">Ticket</th>
                                </tr>
                            </thead>
                            <tbody>`;
                                
                            for( var i = 0; i < entrantCount; ++i ) {
                                updatedTableHTML = updatedTableHTML +
                                    `<tr>
                                        <td class="emailAddressColumn">` + entrants[ i ].emailAddress + `</td> 
                                        <td class="ticketNumberColumn">` + entrants[ i ].number + `</td>
                                    </tr>`;
                            }

                            updatedTableHTML = updatedTableHTML + `</tbody>`;
                        }
                    
                        document.getElementById( 'entrantTable' ).innerHTML = updatedTableHTML;  
                    }
                } )
                .catch( error => {
                    console.log( "Error: " + error.message );
                } );            
        }
        else if( selectedEventId !== undefined && selectedEventId !== '' ) {

            const url = `${process.env.REACT_APP_CYASOON_GET_ALL_ASSIGNED_TICKETS_TO_ALL_USERS_BY_EVENT_ROUTE}/cyasoonEventId=${selectedEventId}`;

            axios.get( url )
                .then( response => {
                    let data = response.data;

                    if( data.entrants.length > 0 ) {
                        let entrants = data.entrants;
                        var entrantCount = 0;
                        
                        if( entrants === undefined || entrants.length === 0 ) {
                            entrantCount = 0;
                        }
                        else {
                            entrantCount = entrants.length;
                        }

                        var updatedTableHTML = "";

                        if( entrantCount === 0 ) {
                            updatedTableHTML = 
                            `<thead>
                                <tr>
                                    <th class="emailAddressHeading">Email Address</th>
                                    <th class="ticketNumberHeading">Ticket</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="emailAddressColumn" colspan="2" style="text-align: center;">No entrants available</td>
                                </tr>
                            </tbody>`;
                        }
                        else {
                            updatedTableHTML = 
                            `<thead>
                                <tr>
                                    <th class="emailAddressHeading">Email Address</th>
                                    <th class="ticketNumberHeading">Ticket</th>
                                </tr>
                            </thead>
                            <tbody>`;
                                
                            for( var i = 0; i < entrantCount; ++i ) {
                                updatedTableHTML = updatedTableHTML +
                                    `<tr>
                                        <td class="emailAddressColumn">` + entrants[ i ].emailAddress + `</td> 
                                        <td class="ticketNumberColumn">` + entrants[ i ].number + `</td>
                                    </tr>`;
                            }

                            updatedTableHTML = updatedTableHTML + `</tbody>`;
                        }
                    
                        document.getElementById( 'entrantTable' ).innerHTML = updatedTableHTML;  
                    }
                } )
                .catch( error => {
                    console.log( "Error: " + error.message );
                } );
        }
        else {

            var updatedTableHTML = "";
            updatedTableHTML = 
                 `<thead>` + 
                     `<tr>` +
                         `<th class="emailAddressHeading">Email Address</th>` +
                         `<th class="ticketNumberHeading">Ticket</th>` +
                     `</tr>` +
                 `</thead>` +
                 `<tbody>` +
                     `<tr>` +
                         `<td class="emailAddressColumn" colspan="2" style="text-align: center;">No entrants available</td>` +
                    `</tr>` +
                `</tbody>`;

            document.getElementById( 'entrantTable' ).innerHTML = updatedTableHTML;
        }
    }

    //==============================================================================

};

//==================================================================================
//==================================================================================

export default EntrantPanel;